// eslint-disable-next-line
import { useState } from "react"
import { useForm } from "react-hook-form"
import "twin.macro"
import { Button, Input, TextArea } from "../components/contact"
import React from "react"
import Layout from "../components/layout"

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const contactData = {
  title: "SEND US AN EMAIL",
  content:
    "Do you have any questions, suggestions, complaints, or need specific information? Send us a message. It will be a pleasure to answer you.",
  email: "email",
  input: [
    {
      name: "name",
      placeholder: "Enter your name",
      required: "Enter a name!",
    },
    {
      name: "email",
      placeholder: "Enter your email",
      required: "Enter a email address!",
      pattern: {
        value: EMAIL_REGEX,
        message: "Invalid email address",
      },
    },
    {
      name: "phone",
      placeholder: "Enter your phone",
      required: "Enter a phone!",
      pattern: {
        value: /^[0-9]+$/,
        message: "Phone Number can only contain digits.",
      },
    },
    {
      name: "subject",
      placeholder: "Enter your subject",
      required: "Enter a Subject!",
    },
  ],
  message: {
    name: "message",
    placeholder: "Enter your message",
    required: "Enter a message!",
  },
  send: " Send ",
}

type Inputs = {
  email: string
  password: string
}

const Contact = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onSubmit = (data: any) => {
    console.log(data)
  }

  return (
    <Layout>
      <main tw="mx-auto max-w-3xl my-10  space-y-5 p-5">
        <h1 tw="text-4xl md:text-3xl  font-black text-center">
          {contactData.title}
        </h1>
        <p tw="text-center">{contactData.content}</p>
        <form onSubmit={handleSubmit(onSubmit)} tw="">
          {/* Email and Password */}

          {contactData.input.map((item, index) => (
            <Input
              key={index}
              isError={errors[item.name]}
              label={item.name}
              placeholder={item.placeholder}
              register={register(item.name as any, {
                required: item.required,
                pattern: item.pattern,
              })}
            />
          ))}

          <TextArea
            isError={errors[contactData.message.name]}
            label={contactData.message.name}
            placeholder={contactData.message.placeholder}
            register={register(contactData.message.name as any, {
              required: contactData.message.required,
            })}
          />
          <div tw="flex justify-end">
            <Button isLoading={isLoading} name={contactData.send} />
          </div>
        </form>
      </main>
    </Layout>
  )
}

export default Contact
